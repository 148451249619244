import * as React from "react";
import { cn } from "../../utils";
import { cva } from "class-variance-authority";

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size" | "prefix"> {
  /**
   * Specifies the `size` HTML attribute, which should be the number of characters for the input's length.
   */
  htmlSize?: HTMLInputElement["size"];

  /**
   * Component to be used as the start icon, which will be displayed at the beginning of the input.
   * Expected to be a Lucide icon component.
   */
  prefix?: React.ReactNode;

  /**
   * Component to be used as the end icon, which will be displayed at the end of the input.
   * Expected to be a Lucide icon component.
   */
  suffix?: React.ReactNode;

  /**
   * A React node such as text, icons, or other elements to be rendered at the start of the input.
   * This will be placed before the input field content.
   */
  startAddon?: React.ReactNode;

  /**
   * A React node such as text, icons, or other elements to be rendered at the end of the input.
   * This will be placed after the input field content.
   */
  endAddon?: React.ReactNode;

  /**
   * Defines the size of the input, which determines the padding and font size.
   * Can be one of 'xs', 'sm', 'md', or 'lg'.
   */
  size?: "sm" | "md";

  /**
   * If set to true, the input will display styles that typically indicate an error, such as a red border.
   */
  error?: boolean;

  /**
   * If set to true, the input will display styles that typically indicate success, such as a green border.
   */
  success?: boolean;
  inputClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ inputClassName, type, className, ...props }, ref) => {
  const inputVariants = cn(
    inputStyles({
      size: props.size,
      prefix: !!props.prefix,
      suffix: !!props.suffix,
      error: props.error,
      success: props.success,
      startAddon: !!props.startAddon,
      endAddon: !!props.endAddon,
    }),
    inputClassName,
  );
  const { prefix, suffix, startAddon, endAddon, ...rest } = props;
  if (!props.prefix && !props.suffix && !props.startAddon && !props.endAddon) {
    return (
      <div className={cn("fs-input relative flex items-center", className)}>
        <input type={type} className={inputVariants} ref={ref} {...rest} size={props.htmlSize} />
      </div>
    );
  }
  const prefixClassName = prefix ? cn(prefixStyles({ size: props.size })) : "";
  const suffixClassName = suffix
    ? cn(suffixStyles({ size: props.size, error: props.error, success: props.success }))
    : "";
  const startAddonClassName = startAddon ? cn(startAddonStyles({ size: props.size, error: props.error })) : "";
  const endAddonClassName = endAddon
    ? cn(endAddonStyles({ size: props.size, error: props.error, success: props.success }))
    : "";
  return (
    <div className={cn("fs-input relative flex items-center", className)}>
      {startAddon && <div className={startAddonClassName}>{startAddon}</div>}
      {prefix && <div className={prefixClassName}>{prefix}</div>}
      <input type={type} className={inputVariants} ref={ref} {...rest} size={props.htmlSize} />
      {suffix && <div className={suffixClassName}>{suffix}</div>}
      {endAddon && <div className={endAddonClassName}>{endAddon}</div>}
    </div>
  );
});

export const inputStyles = cva(
  "fs-input placeholder:text-muted-foreground focus-visible:ring-ring flex w-full rounded-md border bg-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50 border-border text-content disabled:bg-muted",
  {
    variants: {
      size: {
        sm: "h-7 text-sm file:text-sm px-2  leading-7",
        md: "h-9 text-sm file:text-sm px-3 leading-9",
        lg: "h-10 text-base file:text-base px-4 leading-10",
      },
      prefix: {
        true: "",
        false: "",
      },
      suffix: {
        true: "",
        false: "",
      },
      error: {
        true: "ring-warning/80 border-warning/80 focus-visible:ring-warning/80",
      },
      success: {
        true: "ring-success/80 border-success/80 focus-visible:ring-success/80",
      },
      startAddon: {
        true: "rounded-l-none",
      },
      endAddon: {
        true: "rounded-r-none",
      },
      hoverborder: {
        true: "hover:border-border hover:ring-1 hover:ring-border border-none",
      },
    },
    compoundVariants: [
      {
        prefix: true,
        size: "sm",
        class: "pl-8",
      },
      {
        prefix: true,
        size: "md",
        class: "pl-9",
      },
      {
        suffix: true,
        size: "sm",
        class: "pr-8",
      },
      {
        suffix: true,
        size: "md",
        class: "pr-9",
      },
    ],
    defaultVariants: {
      size: "md",
      prefix: false,
    },
  },
);

const iconBaseStyles = "text-content/50 absolute flex items-center flex-shrink-0";
const prefixStyles = cva(iconBaseStyles, {
  variants: {
    size: {
      sm: "ml-2 size-4",
      md: "ml-2 size-5",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const suffixStyles = cva(iconBaseStyles, {
  variants: {
    size: {
      sm: "right-2 size-4",
      md: "right-2 size-5",
    },
    error: {
      true: "text-warning/80",
    },
    success: {
      true: "text-success",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const startAddonStyles = cva(
  "bg-muted inline-flex items-center rounded-l-md border border-r-0 border-border px-3 shadow-sm text-content/70",
  {
    variants: {
      size: {
        sm: "h-7 text-sm px-2 py-1 leading-7",
        md: "h-9 text-sm px-3 py-1 leading-9",
      },
      error: {
        true: "border-warning/80",
      },
      success: {
        true: "border-success",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

const endAddonStyles = cva(
  "bg-muted inline-flex items-center rounded-r-md border border-l-0 border-border px-3 shadow-sm text-content/70",
  {
    variants: {
      size: {
        sm: "h-7 text-sm px-2 leading-7",
        md: "h-9 text-sm px-3 py-1 leading-9",
      },
      error: {
        true: "border-warning/80",
      },
      success: {
        true: "border-success",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

Input.displayName = "Input";

export { Input };
